import React from 'react';

import range from 'lodash/range';

import {
    HeroBanner,
    CaseHeader,
    Carousel,
    DeviceType,
    Divider,
    Testimonial,
    TextContent,
    Gallery,
    GalleryLayout,
    Team,
} from 'modules/references';

import socialPreview from '../../../static/social-previews/citymove.png';
import Seo from '../../components/SEO';
const team = [
    { department: 'references.case.team.management', names: ['Josef Frnka', 'Jan Vítek', 'Marek Přibáň'] },
    { department: 'references.case.team.ux', names: ['Michal Mikolaj'] },
    { department: 'references.case.team.ui', names: ['Roman Gordienko'] },
    {
        department: 'references.case.team.android',
        names: ['David Bilík', 'Andrew Fitzimons', 'Roman Polach', 'Jan Mottl', 'Petr Kubáč'],
    },
    {
        department: 'references.case.team.ios',
        names: ['Jan Mísař', 'Jakub Olejník', 'Karel Leinhaupl', 'Tomáš Holka'],
    },
    { department: 'references.case.team.testing', names: ['Julia Furmanyuk', 'Maxim Rubáš'] },
];

const CityMove = () => (
    <>
        <Seo image={socialPreview} pageId="citymove" />
        <CaseHeader />
        <HeroBanner
            headerTheme="light"
            content={{
                projectName: 'case.citymove.hero.project',
                projectTitle: 'case.citymove.hero.title',
                projectYear: 'case.citymove.hero.year',
                client: 'case.citymove.hero.client',
                image: {
                    src: 'references/case-studies/citymove/hero_image.png',
                    imgStyle: { objectFit: 'contain', objectPosition: 'center bottom' },
                },
            }}
            background={'#0081f9'}
            textColor={'#ffffff'}
            extend={{
                mainContainer: () => ({
                    tabletMax: {
                        height: 'auto',
                    },
                }),
                container: () => ({
                    tabletMax: {
                        height: 'auto',
                    },
                }),
                titleContainer: () => ({
                    desktop1200: {
                        paddingTop: 0,
                    },
                    maxWidth: '50rem',
                }),
            }}
        />
        <TextContent
            content={{
                section: 'case.citymove.section1.name',
                title: `case.citymove.section1.title`,
                text: `case.citymove.section1.text`,
                outputs: [
                    'references.case.output.ux',
                    'references.case.output.design',
                    'references.case.output.android',
                    'references.case.output.ios',
                ],
            }}
        />
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.ONE_HALF}
            content={[
                {
                    background: '#F0F6FF',
                    image: {
                        src: 'references/case-studies/citymove/1.png',
                        imgStyle: { objectFit: 'contain', objectPosition: 'center center' },
                    },
                },
                {
                    background: '#F0F6FF',
                    image: {
                        src: 'references/case-studies/citymove/2.png',
                        imgStyle: { objectFit: 'contain', objectPosition: 'center center' },
                    },
                },
            ]}
        />
        <TextContent
            content={{
                section: 'case.citymove.section2.name',
                title: `case.citymove.section2.title`,
                text: `case.citymove.section2.text`,
            }}
        />
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.ONE_THIRD}
            content={[
                {
                    background: '#F0F6FF',
                    image: {
                        src: 'references/case-studies/citymove/3.png',
                        imgStyle: { objectFit: 'contain', objectPosition: 'center bottom' },
                    },
                },
                {
                    background: '#0081F9',
                    image: {
                        src: 'references/case-studies/citymove/4.png',
                        imgStyle: { objectFit: 'contain', objectPosition: 'center center' },
                    },
                },
            ]}
        />
        <TextContent
            content={{
                section: 'case.citymove.section3.name',
                title: `case.citymove.section3.title`,
                text: `case.citymove.section3.text`,
            }}
        />
        <Gallery
            headerTheme="dark"
            layout={GalleryLayout.THREE_QUARTERS}
            content={[
                {
                    background: '#0081F9',
                    image: {
                        src: 'references/case-studies/citymove/5.png',
                        imgStyle: { objectFit: 'contain', objectPosition: 'center center' },
                    },
                },
                {
                    background: '#F0F6FF',
                    image: {
                        src: 'references/case-studies/citymove/6.png',
                        imgStyle: { objectFit: 'contain', objectPosition: 'center center' },
                    },
                },
            ]}
        />
        <Divider height={'1.25rem'} />
        <Carousel
            headerTheme="dark"
            deviceType={DeviceType.MOBILE}
            background="#F5FFF0"
            slides={range(1, 8).map(id => ({
                image: {
                    src: `references/case-studies/citymove/carousel/${id}.png`,
                },
            }))}
        />

        <TextContent
            content={{
                section: 'case.citymove.section4.name',
                title: `case.citymove.section4.title`,
                text: `case.citymove.section4.text`,
            }}
        />

        <Gallery
            layout={GalleryLayout.EQUAL}
            background={'#0082f8'}
            fullHeight
            content={[
                {
                    image: {
                        src: 'references/case-studies/citymove/8.jpg',
                        imgStyle: { objectFit: 'cover', objectPosition: 'center center' },
                    },
                },
            ]}
        />

        <Testimonial
            type="PROFILE_RIGHT"
            headerTheme="dark"
            background={'#F9F9F9'}
            content={{
                quote: 'case.citymove.testimonial.text',
                person: {
                    name: 'case.citymove.testimonial.name',
                    role: 'case.citymove.testimonial.position',
                    image: {
                        src: 'references/case-studies/citymove/person.png',
                    },
                },
            }}
        />

        <Team content={team} />
    </>
);

export default CityMove;
